import React, { useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'
import Image from 'components/elements/Image'

// Images
import Close from '../img/x.svg'

import './modal.css'

const StreamImage = styled(Image)`
  width: 200px;
  position: absolute !important;
  top: 0;
  z-index: 6;
  cursor: pointer;
`

const Content = styled(ParseContent)`
  @media (max-width: 991px) {
    width: 100% !important;
    height: 350px !important;
  }
`

const Button = styled.button`
  font-size: 16px;
  color: ${(props) => props.theme.color.text.contrast};
  font-weight: ${(props) => props.theme.font.weight['300']};
  padding: 0;
`

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
}

function App({ link, image, content }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div>
      {image && (
        <button type="button" onClick={() => openModal()}>
          <StreamImage src={image} onClick={() => openModal()} />
        </button>
      )}
      {content && <Button onClick={() => openModal()}>{content}</Button>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="text-right">
          <button type="button" onClick={closeModal}>
            <img src={Close} alt="" />
          </button>
        </div>
        <Content content={link.iframe} />
      </Modal>
    </div>
  )
}

export default App
